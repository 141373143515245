<template>
	<div id="view-contacts">
		<img src="../assets/Essenza Cofani - Mappa.png" alt="Mappa" class="img-responsive center-block" style="margin-bottom: 8px">
		<a id="button-bring-me-here" target="_blank" href="https://www.google.com/maps/place/Essenza+S.R.L./@45.5054731,10.3911465,17z/data=!4m12!1m6!3m5!1s0x478199883678c669:0x6175fa52a144e4f3!2sEssenza+S.R.L.!8m2!3d45.5054731!4d10.3933352!3m4!1s0x478199883678c669:0x6175fa52a144e4f3!8m2!3d45.5054731!4d10.3933352">
			PORTAMI QUI
		</a>
		<br>
		<p style="text-align: center; font-weight: bold;">Essenza Cofani Srl</p>
		<p style="text-align: center;">
			Via Gavardina Traversa I, n. 40
			<br>
			25081 Bedizzole (BS)
		</p>
		<br>
		<div style="text-align: center;">
			<a class="icon" href="tel:+390306755510" target="_blank">
				<img src="../assets/icon_tel.png">
			</a>
			<a class="icon" href="mailto:info@essenzacofani.eu" target="_blank">
				<img src="../assets/icon_mail.png">
			</a>
			<a class="icon" href="https://essenzacofani.eu" target="_blank">
				<img src="../assets/icon_www.png">
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "Contacts",
}
</script>

<style>
#view-contacts {
	padding: 8px;
}
#button-bring-me-here {
	text-decoration: none;
	text-align: center;
	padding: 8px;
	display: block;

	font-weight: bold;
	color: white;
	background-color: #AAA;
	border-radius: 4px;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
#button-bring-me-here:hover {
	background-color: #BBB;
}
a.icon {
	margin-left: 4px;
	margin-right: 4px;
}
a.icon img {
	min-height: 32px;
	height: 32px;
	min-width: 32px;
	width: 32px;
}
</style>